body {
  background-color: #f8f9fa;
}

main {
  margin: 0 auto;
}

header {
  background-color: #fff;
  padding-top: 32px;
  padding-bottom: 32px;
  box-shadow: 0 2px 2px rgb(0 0 0 / 20%);
}

.logo {
  max-width: 200px;
  margin-left: 40px;
}

header {
  display: flex;
  justify-content: space-between;
}

header .loggedUser {
  margin-right: 40px;
  text-align: right;
}

header .loggedUser a {
  text-decoration: none;
  color: #000;
  font-weight: bold;
}

h1 {
  font-family: "Open Sans", "Segoe UI", Tahoma, sans-serif;
  font-size: 32px !important;
  color: #213848;
  font-weight: 300;
  margin-bottom: 0;
  margin-top: 0;
}

.formContainer {
  margin-bottom: 10px;
  padding: 10px 20px 10px 20px;
  border: 1px solid #ddd;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.formContainer label{
  display: flex;
}

.formContainer > div {
  width: 100%;
  margin: 0 10px;
}

.formContainer div input{
  width: calc(100% - 20px);
  padding: 10px;
  border: 1px solid #eee;
  border-radius: 3px;
}

.dateRangeContainer {
  display: flex;
  width: 400px;
}

.formContainer div.dateRangeContainer input {
  width: calc(100% - 30px);
}

.formContainer div.buttonContainer {
  align-self: flex-end;
}

.formContainer div.buttonContainer button{
  padding: 9px;
  width: 150px;
}

.chartDataContainer {
  background-color: #fff;
  margin: 20px;
  border-radius: 3px;
  box-shadow: 0 2px 2px rgb(0 0 0 / 20%);
  padding: 20px;
}

.react-datepicker__month-text.react-datepicker__month-text--keyboard-selected {
  background-color: transparent;
  color: #000;
}

.react-datepicker__month-text.react-datepicker__month--selected.react-datepicker__month-text--keyboard-selected.react-datepicker__month--in-range.react-datepicker__month--range-start,
.react-datepicker__month-text.react-datepicker__month--selected.react-datepicker__month-text--keyboard-selected.react-datepicker__month--in-range.react-datepicker__month--range-end {
  background-color: #216ba5;
  color: #fff;
}

.react-datepicker__month-text.react-datepicker__month--disabled.react-datepicker__month-text--keyboard-selected {
  color: #ccc;
  pointer-events: none;
}

#graph-size-control {
  display: flex;
  height: 650px;
  justify-content: center;
  width: 100%;
}

@media only screen and (max-width: 1366px){
  
  #graph-size-control {
    height: 450px;
  }

}