.tableWrapper {
    margin: 10px 0 20px 0;
    padding-left: 10px;
}

table {
    table-layout: fixed;
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 3px;
    box-shadow: 2px 2px 4px #ddd;
}


table thead td {
    font-weight: 700;
}

table td {
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
}
